import getLocale from './getLocale'
import getBeerLink from './getBeerLink'

const getTranslations = (currentLocale, translations, customSlug = false) => {
  if (Array.isArray(translations)) {
    const otherTranslations = translations.filter(
      (translation) => translation.polylang_current_lang !== currentLocale,
    )

    const newTranslations = otherTranslations.map((translation) => {
      const locale = getLocale(translation.polylang_current_lang)
      let { slug } = translation

      if (
        slug === 'homepage' ||
        slug === 'hlavni-strana'
      ) {
        slug = ''
      }

      let newSlug = `/${locale}/${slug}${
          slug === '' ? '' : '/'
        }`

      if (customSlug) {
        newSlug = getBeerLink({
          currentLang: locale,
          slug,
        })
      }

      return {
        locale: translation.polylang_current_lang,
        slug: newSlug,
      }
    })

    return newTranslations
  }

  return [{
    currentLocale,
    slug: `/${getLocale(currentLocale)}/`,
  }]
}

export default getTranslations
