/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Tilt from 'react-parallax-tilt'
import styled, { css } from 'styled-components'
import { Flex, Button } from 'rebass/styled-components'

const StyledTilt = styled(Tilt)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
`

const foregroundItemsStyle = css`
  position: absolute;
  width: ${(props) => `${props.width}%`};
  /* left: ${(props) => `${(100 - props.width) / 2}%`}; */
`

const StyledBackground = styled.div``

const StyledTop = styled.div`
  ${foregroundItemsStyle};
  transform: translateZ(1rem);
`

const StyledBottom = styled.div`
  ${foregroundItemsStyle};
  transform: translateZ(2rem);
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
`

const StyledGatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
`

const requestPermission = (setRequested) => {
  if (typeof window !== 'undefined' && typeof DeviceMotionEvent !== 'undefined') {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            setRequested(true)
          }
        })
        .catch()
    }
  }
}

const TiltLabel = ({
  background, top, bottom, showToggle, disableTilt, size,
}) => {
  const graphicsSizePercent = 100
  const nameSizePercent = 100
  const [requested, setRequested] = React.useState((typeof window !== 'undefined' && typeof DeviceMotionEvent !== 'undefined'))
  const [move, setMove] = React.useState(true)

  const handleLabelClick = () => {
    if (!requested) {
      requestPermission(setRequested)
    }
    setMove(!move)
  }

  const backgroundStyle = { width: size || '20rem', height: size || '20rem' }

  return (
    <Flex sx={{
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <StyledTilt
        tiltEnable={move && !disableTilt}
        perspective={1000}
        tiltReverse
        gyroscope
        tiltMaxAngleX={10}
        tiltMaxAngleY={10}
      >
        {background && (
        <StyledBackground>
          {typeof background === 'string'
            && <StyledImg src={background} alt="" style={backgroundStyle} />}
          {typeof background === 'object'
            && <StyledGatsbyImg fluid={background} alt="" style={backgroundStyle} />}
        </StyledBackground>
        )}

        {top && (
        <StyledTop width={graphicsSizePercent}>
          {typeof top === 'string'
            && <StyledImg src={top} alt="" />}
          {typeof top === 'object'
            && <StyledGatsbyImg fluid={top} alt="" />}
        </StyledTop>
        )}

        {bottom && (
        <StyledBottom width={nameSizePercent}>
          {typeof bottom === 'string'
            && <StyledImg src={bottom} alt="" />}
          {typeof bottom === 'object'
            && <StyledGatsbyImg fluid={bottom} alt="" />}
        </StyledBottom>
        )}
      </StyledTilt>

      {showToggle && (
        <Button
          type="button"
          variant="primary"
          onClick={handleLabelClick}
        >
          {move ? 'Nehýbej se!' : 'Hýbej se'}
        </Button>
      )}
    </Flex>
  )
}

TiltLabel.propTypes = {
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  bottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  showToggle: PropTypes.bool,
  disableTilt: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

TiltLabel.defaultProps = {
  showToggle: false,
  disableTilt: false,
  size: '20rem',
}

export default TiltLabel
